
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46pageSlug_93qeeyZC7qXuMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activateOwadycLJUaMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/activate.vue?macro=true";
import { default as index9qYPCAVQAtMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/index.vue?macro=true";
import { default as _91securityKey_93h4C2lmzHGPMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newgspROXUN00Meta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/password/new.vue?macro=true";
import { default as watchlistiwS27qliBHMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/watchlist.vue?macro=true";
import { default as indexRMkPwBqsZ2Meta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/index.vue?macro=true";
import { default as edita47O18sxhrMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/edit.vue?macro=true";
import { default as indexTFzCb0gmoUMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/index.vue?macro=true";
import { default as addhf97pgRk7FMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/add.vue?macro=true";
import { default as rafflestQH4A9eTccMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffles.vue?macro=true";
import { default as indexAgqqj76VTsMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infos8c6hBkUxyMMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as program0AOUhpytS6Meta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as _91showSlug_93seuO28N8iCMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_93mssMp1kNOPMeta } from "/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/cms/pages/[...pageSlug].vue")
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/activate.vue")
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/activate.vue")
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/index.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/index.vue")
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/index.vue")
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber()/:securityKey()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/order/[orderNumber]/[securityKey].vue")
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/password/new.vue")
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/account/pages/account/watchlist.vue")
  },
  {
    name: "admin___de",
    path: "/admin",
    meta: indexRMkPwBqsZ2Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___en",
    path: "/en/admin",
    meta: indexRMkPwBqsZ2Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: indexRMkPwBqsZ2Meta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/index.vue")
  },
  {
    name: "admin-raffle-id-edit___de",
    path: "/admin/raffle/:id()/edit",
    meta: edita47O18sxhrMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___en",
    path: "/en/admin/raffle/:id()/edit",
    meta: edita47O18sxhrMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id-edit___es",
    path: "/es/admin/raffle/:id()/edit",
    meta: edita47O18sxhrMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/edit.vue")
  },
  {
    name: "admin-raffle-id___de",
    path: "/admin/raffle/:id",
    meta: indexTFzCb0gmoUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___en",
    path: "/en/admin/raffle/:id",
    meta: indexTFzCb0gmoUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-id___es",
    path: "/es/admin/raffle/:id",
    meta: indexTFzCb0gmoUMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/[id]/index.vue")
  },
  {
    name: "admin-raffle-add___de",
    path: "/admin/raffle/add",
    meta: addhf97pgRk7FMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___en",
    path: "/en/admin/raffle/add",
    meta: addhf97pgRk7FMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffle-add___es",
    path: "/es/admin/raffle/add",
    meta: addhf97pgRk7FMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffle/add.vue")
  },
  {
    name: "admin-raffles___de",
    path: "/admin/raffles",
    meta: rafflestQH4A9eTccMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___en",
    path: "/en/admin/raffles",
    meta: rafflestQH4A9eTccMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: "admin-raffles___es",
    path: "/es/admin/raffles",
    meta: rafflestQH4A9eTccMeta || {},
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/layers/builder/pages/admin/raffles.vue")
  },
  {
    name: _91movieSlug_93mssMp1kNOPMeta?.name,
    path: "/film/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug].vue"),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/index.vue")
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/infos.vue")
  },
  {
    name: "movie-movieSlug-program___de",
    path: "spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/program.vue")
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "vorstellung/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue")
  }
]
  },
  {
    name: _91movieSlug_93mssMp1kNOPMeta?.name,
    path: "/en/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug].vue"),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/index.vue")
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/infos.vue")
  },
  {
    name: "movie-movieSlug-program___en",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/program.vue")
  },
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue")
  }
]
  },
  {
    name: _91movieSlug_93mssMp1kNOPMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug].vue"),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/index.vue")
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/infos.vue")
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/program.vue")
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2025-04-09_09-37-03_4ce3221/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue")
  }
]
  }
]